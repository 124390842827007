import React from 'react'
//Types
import { SectionBaseProps, SectionTitleOnly } from '../../../types/sections'
//Components
import { Box, Heading } from '../../../atoms'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'

export interface TitleOnlyProps extends SectionBaseProps {
  data: SectionTitleOnly
}

const TitleOnly: React.FC<TitleOnlyProps> = ({ data }) => {
  const { id, sectionId, titleType, title, alignment } = data
  return (
    <Box as={'section'} id={sectionId || id} className={'p-section'} my={[80, 80, 120]}>
      <Grid>
        <Row
          center={alignment === 'center' ? 'xs' : undefined}
          start={alignment === 'left' ? 'xs' : undefined}
          end={alignment === 'right' ? 'xs' : undefined}
        >
          <Col xs={12} sm={10} md={7}>
            <Heading as={titleType} textAlign={alignment}>
              {title}
            </Heading>
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}

export default TitleOnly
